import React, { useState, useEffect } from 'react';

interface Scripture {
  verse: string;
  reference: string;
  translation: string;
}

const DAILY_SCRIPTURES: Scripture[] = [
  {
    verse: "For I know the plans I have for you, declares the Lord, plans to prosper you and not to harm you, plans to give you hope and a future.",
    reference: "Jeremiah 29:11",
    translation: "NIV"
  },
  {
    verse: "Trust in the Lord with all your heart and lean not on your own understanding; in all your ways submit to him, and he will make your paths straight.",
    reference: "Proverbs 3:5-6",
    translation: "NIV"
  },
  {
    verse: "I can do all this through him who gives me strength.",
    reference: "Philippians 4:13",
    translation: "NIV"
  },
  {
    verse: "Be strong and courageous. Do not be afraid; do not be discouraged, for the Lord your God will be with you wherever you go.",
    reference: "Joshua 1:9",
    translation: "NIV"
  },
  {
    verse: "The Lord is my shepherd, I lack nothing. He makes me lie down in green pastures, he leads me beside quiet waters, he refreshes my soul.",
    reference: "Psalm 23:1-3",
    translation: "NIV"
  }
];

const ScriptureComponent: React.FC = () => {
  const [scripture, setScripture] = useState<Scripture | null>(null);

  useEffect(() => {
    // Get a random scripture
    const randomScripture = DAILY_SCRIPTURES[Math.floor(Math.random() * DAILY_SCRIPTURES.length)];
    setScripture(randomScripture);
  }, []);

  const getNewScripture = () => {
    let newScripture;
    do {
      newScripture = DAILY_SCRIPTURES[Math.floor(Math.random() * DAILY_SCRIPTURES.length)];
    } while (newScripture === scripture);
    setScripture(newScripture);
  };

  if (!scripture) return null;

  return (
    <div className="card">
      <h2 className="text-xl font-semibold mb-4">Daily Scripture</h2>
      <div className="space-y-4">
        <blockquote className="italic text-lg text-gray-700 border-l-4 border-primary-500 pl-4">
          {scripture.verse}
        </blockquote>
        <div className="flex justify-between items-center">
          <div>
            <p className="font-semibold text-gray-900">{scripture.reference}</p>
            <p className="text-sm text-gray-500">{scripture.translation}</p>
          </div>
          <button
            onClick={getNewScripture}
            className="btn btn-secondary"
          >
            New Verse
          </button>
        </div>
      </div>
    </div>
  );
};

export default ScriptureComponent;
