import React, { useState } from 'react';
import { analyzeJournalEntry } from '../services/cloudflareAI';

interface Analysis {
  insights: string[];
  scriptures: string[];
  nextSteps: string[];
}

const Journal: React.FC = () => {
  const [journalEntry, setJournalEntry] = useState('');
  const [analysis, setAnalysis] = useState<Analysis | null>(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleAnalyze = async () => {
    if (!journalEntry.trim() || isAnalyzing) return;

    setIsAnalyzing(true);
    setError(null);

    try {
      const result = await analyzeJournalEntry(journalEntry);
      setAnalysis(result);
    } catch (error) {
      console.error('Error:', error);
      setError('Failed to analyze journal entry. Please try again.');
    } finally {
      setIsAnalyzing(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="card">
        <h2 className="text-xl font-semibold mb-4">Spiritual Journal</h2>
        <textarea
          value={journalEntry}
          onChange={(e) => setJournalEntry(e.target.value)}
          placeholder="Write about your spiritual journey, prayers, or reflections..."
          className="input h-48 resize-none mb-4"
          disabled={isAnalyzing}
        />
        <button
          onClick={handleAnalyze}
          disabled={isAnalyzing || !journalEntry.trim()}
          className={`btn btn-primary w-full ${isAnalyzing ? 'opacity-75 cursor-not-allowed' : ''}`}
        >
          {isAnalyzing ? (
            <span className="flex items-center justify-center">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Analyzing...
            </span>
          ) : (
            'Analyze Entry'
          )}
        </button>
        {error && (
          <p className="mt-2 text-red-500 text-sm">{error}</p>
        )}
      </div>

      {analysis && (
        <div className="card space-y-6">
          <div>
            <h3 className="font-semibold text-lg mb-2">Spiritual Insights</h3>
            <ul className="list-disc list-inside space-y-2">
              {analysis.insights.map((insight, index) => (
                <li key={index} className="text-gray-700">{insight}</li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className="font-semibold text-lg mb-2">Relevant Scriptures</h3>
            <ul className="list-disc list-inside space-y-2">
              {analysis.scriptures.map((scripture, index) => (
                <li key={index} className="text-gray-700">{scripture}</li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className="font-semibold text-lg mb-2">Next Steps</h3>
            <ul className="list-disc list-inside space-y-2">
              {analysis.nextSteps.map((step, index) => (
                <li key={index} className="text-gray-700">{step}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Journal;
