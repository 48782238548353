interface Message {
  role: 'system' | 'user' | 'assistant';
  content: string;
}

const WORKER_URL = 'https://christian-ai-worker.trottaa.workers.dev';

const SYSTEM_PROMPT = `You are a compassionate Christian AI accountability partner. Your role is to:
1. Provide biblical guidance and encouragement
2. Help users reflect on their spiritual journey
3. Offer relevant scripture references when appropriate
4. Ask thoughtful questions to promote spiritual growth
5. Maintain a non-judgmental, loving approach
6. Help users set and track spiritual goals
Always respond with empathy and wisdom, grounded in Christian principles.`;

export const generateChatResponse = async (message: string, context: Message[] = []): Promise<string> => {
  try {
    const messages: Message[] = [
      { role: 'system', content: SYSTEM_PROMPT },
      ...context,
      { role: 'user', content: message }
    ];

    console.log('Sending request to worker:', { type: 'chat', messages });

    const response = await fetch(WORKER_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: 'chat',
        messages
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Worker response error:', response.status, errorText);
      throw new Error(`API error: ${response.status} - ${errorText}`);
    }

    const data = await response.json();
    console.log('Worker response:', data);
    return data.result.response;
  } catch (error) {
    console.error('Error in generateChatResponse:', error);
    throw error;
  }
};

export const analyzeJournalEntry = async (journalEntry: string): Promise<{
  insights: string[];
  scriptures: string[];
  nextSteps: string[];
}> => {
  try {
    console.log('Sending journal entry to worker:', journalEntry);

    const response = await fetch(WORKER_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: 'journal',
        content: journalEntry
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Worker response error:', response.status, errorText);
      throw new Error(`API error: ${response.status} - ${errorText}`);
    }

    const data = await response.json();
    console.log('Worker response:', data);
    return data.result;
  } catch (error) {
    console.error('Error in analyzeJournalEntry:', error);
    throw error;
  }
};
